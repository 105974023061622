window.HMSBuyNow = class BuyNow {
	constructor() {
    // Added timeout to delay the script execution
    setTimeout(() => {
      this.init();
    }, 0);
	}

	/**
	 * Used to hide/show correct dropdowns for packaging
	 */
	onSelectVariant() {
		$('.js-select-variant').on('select2:select', event => {
			$('.js-dropdown-package').hide();
			$('.js-product-list').hide();
			const packageDropdown = `.dropdown-package-${event.params.data.id}`;
			$(packageDropdown).show();
			const productList = `.product-list-${$('.js-select-package:visible').val()}`;
			this.checkRetailerStock(productList);
			this.onSelectPlaceholder(event.params.data.id);
			$(productList).show();
		});
	}

	/**
	 * Used to hide/show correct retailers and check stock for items
	 */
	onSelectPackage() {
		$('.js-select-package').on('select2:select', event => {
			const packageSelected = event.params.data.id;
			const productList = `.product-list-${packageSelected}`;
			$('.js-product-list').hide();
			this.checkRetailerStock(productList);
			this.onSelectPlaceholder(event.params.data.id);
			$(productList).show();
		});
	}

	/**
	 * @param {String} value - Active Select2 dropdown
	 * Checks if the dropdown is a placeholder.
	 * If true, instructions to select an item from the dropdown will appear
	 */
	onSelectPlaceholder(value) {
		if (value.indexOf('placeholder') > -1) {
			$('.messageInfo-wrap').show();
			$('.disclaimerInfo').hide();
			$('.select-size').show();
			$('.no-retailers').hide();
		}
	}

	/**
	 * @param {String} target - HTML element to be tested
	 * Checks if the target has any HTML elements as childern,
	 * if there are no elements, an out of stock message will be shown.
	 * else it will revert to a previous
	 */
	checkRetailerStock(target) {
		if ($(`${target} div`).length === 0) {
			$('.no-retailers').show();
			$('.messageInfo-wrap').show();
			$('.disclaimerInfo').hide();
			$('.select-size').hide();
		} else {
			$('.no-retailers').hide();
			$('.messageInfo-wrap').hide();
			$('.disclaimerInfo').show();
		}
	}

	init() {
		if ($('.js-variant-dropdowns').length > 0) {
			this.onSelectVariant();
			this.onSelectPackage();
			this.onSelectPlaceholder('placeholder');
		}
		if ($('.js-package-dropdown').length > 0) {
			// Used to load initial retailers.
			const firstPackageDropdown = $('.js-select-package').val();
			const productList = `.product-list-${firstPackageDropdown}`;
			$(productList).removeClass('hidden');
			this.onSelectPackage();
			this.checkRetailerStock(productList);
			this.onSelectPlaceholder(productList);
		}
	}
}